import { categoryType } from '@/lib/category'
import { serviceType } from '@/lib/service'
import { Dispatch, SetStateAction } from 'react'
import { CategoryCard } from '../parts/categoryCard'

type CategoryListProps = {
  categories: categoryType[]
  open?: boolean
  setOpen?: Dispatch<SetStateAction<boolean>>
  service: serviceType
}

export const CategoryList: React.FC<CategoryListProps> = ({
  categories,
  open,
  setOpen,
  service,
}) => {
  return (
    <div
      className=""
      style={{ width: '94%', margin: '12px auto', overflow: 'hidden' }}
    >
      <div className="mb-4">
        <h2 className="text-base font-bold">人気カテゴリ一覧</h2>
      </div>
      <div className="text-center">
        {categories.map((v) => (
          <div
            key={v.category_id}
            onClick={() => {
              if (open && setOpen) {
                setOpen(false)
              }
            }}
          >
            <CategoryCard category={v} service={service} />
          </div>
        ))}
      </div>
    </div>
  )
}
