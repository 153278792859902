/* eslint-disable @typescript-eslint/no-floating-promises */
'use client'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
} from '@/components/ui/dialog'
import { categoryType, getCategories } from '@/lib/category'
import { serviceType } from '@/lib/service'
import { getSubcategories, subcategoryType } from '@/lib/subcategory'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { CategoryList } from './modal/categoryList'
import { ModalSearchBox } from './modalSearch'
import { SubCategoryList } from './subcategoryList'

export function TopModal({
  service,
  text,
  icon = false,
}: {
  service: serviceType
  text?: string
  icon?: boolean
}) {
  const [subcategories, setSubcategories] = useState<subcategoryType[]>([])
  const [categories, setCategories] = useState<categoryType[]>([])
  const [open, setOpen] = useState(false)
  useEffect(() => {
    void (async () => {
      if (service.is_replaced) {
        const categories = await getSubcategories(service.service_id)
        setSubcategories(categories)
      } else {
        const categories = await getCategories(service.service_id)
        setCategories(categories)
      }
    })()
  }, [service])
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger
        asChild
        onClick={() => {
          setOpen(!open)
        }}
      >
        {icon ? (
          <div className="w-[20px] mx-2">
            <FontAwesomeIcon icon={faSearch} fontSize={20} />
          </div>
        ) : (
          <div className="relative flex items-center">
            <p className="text-sm font-semibold">
              {text ? text : '商品を検索する'}
            </p>
            <div className="ml-2 lg:hidden">
              <FontAwesomeIcon icon={faSearch} className="text-base" />
            </div>
          </div>
        )}
      </DialogTrigger>
      <DialogContent
        className="p-3.5 border-0 bg-white w-11/12 rounded-md duration-500 overflow-y-auto max-h-[500px] "
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        <DialogHeader className="mt-4">
          <ModalSearchBox />
        </DialogHeader>
        {service.is_replaced ? (
          <SubCategoryList
            service={service}
            subcategories={subcategories}
            open={open}
            setOpen={setOpen}
          />
        ) : (
          <CategoryList
            service={service}
            categories={categories}
            open={open}
            setOpen={setOpen}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}
