'use client'
import { headerType } from '@/lib/layout/type'
import { serviceType } from '@/lib/service'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { useEffect, useState } from 'react'
import { TopModal } from './topModal'

type FixedHeaderProps = {
  service: serviceType
  header: headerType | null
}

export const OriginalFixedHeader: React.FC<FixedHeaderProps> = ({
  service,
  header,
}) => {
  const [scrollY, setScrollY] = useState<number>(0)
  const path = usePathname()
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY)
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrollY])

  return (
    <div
      className={`fixed-header main-color fixed top-0 left-0 right-0 z-50 ${scrollY > 150 && !path.includes('item') && !path.includes('articles') && !path.includes('pages') ? 'block' : 'hidden'}`}
      style={{ boxShadow: 'rgba(0, 0, 0, 0.18) 0 2px 4px' }}
    >
      <div className="fixed-header-container flex w-[96%] mx-auto justify-between items-center py-2">
        {header ? (
          <>
            {header.navbar.bottom.elements.map((element, index) => {
              if (!element.href || element.href === 'top-modal') {
                return (
                  <div
                    className="bg-white text-center items-center flex h-[42px] rounded-md justify-center"
                    style={{
                      width: `${100 / header.navbar.bottom.elements.length - 1}%`,
                    }}
                    key={index}
                  >
                    <TopModal service={service} text={element.text} />
                  </div>
                )
              }
              return (
                <div
                  className="bg-white text-center items-center flex h-[42px] rounded-md justify-center"
                  key={index}
                  style={{
                    width: `${100 / header.navbar.bottom.elements.length - 1}%`,
                  }}
                >
                  <Link href={element.href}>
                    <p className="text-sm font-semibold">{element.text}</p>
                  </Link>
                </div>
              )
            })}
          </>
        ) : (
          <>
            <div className="fixed-header-left w-[49%] bg-white h-[42px] flex items-center rounded-md text-center justify-center">
              <TopModal service={service} />
            </div>
            <div className="fixed-header-right w-[49%] bg-white text-center items-center flex h-[42px] rounded-md justify-center">
              <Link href="/ranking">
                <p className="font-semibold relative text-sm">人気ランキング</p>
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
