/* eslint-disable @next/next/no-img-element */
'use client'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import { categoryType } from '@/lib/category'
import { serviceType } from '@/lib/service'
import { subcategoryType } from '@/lib/subcategory'
import { ArrowRightCircleIcon } from 'lucide-react'
import Link from 'next/link'

type CategoryCardProps = {
  subcategory?: subcategoryType
  category?: categoryType
  service: serviceType
}
export const CategoryCard: React.FC<CategoryCardProps> = ({
  subcategory,
  category,
  service,
}) => {
  return (
    <>
      {subcategory && !subcategory.hide && (
        <Link
          href={`/${service.is_replaced ? 'search' : 'subcategory'}/${subcategory.subcategory_id}`}
          className="category-card flex justify-between px-4 items-center h-[46px] border-t border-[#efefef]"
        >
          <div className="flex items-center">
            {subcategory.top_image_url && subcategory.top_image_url != '' && (
              <img
                src={subcategory.top_image_url}
                alt={subcategory.name}
                className="category-card-image h-[35px] object-cover"
              />
            )}
            <span className="ml-4 font-semibold">{subcategory.name}</span>
          </div>
          <div className="arrow-right-circle-icon flex items-center">
            <ArrowRightCircleIcon />
          </div>
        </Link>
      )}
      {category && category.subcategories.length > 0 && (
        <Accordion type="single" collapsible className="w-full">
          <AccordionItem
            value={`item-${category.category_id}`}
            className="border-b border-gray-200"
          >
            <AccordionTrigger
              className="py-4 px-6 text-left font-semibold text-sm"
              onClick={(e) => e.stopPropagation()}
            >
              <a
                href={`/category/${category.category_id}`}
                className="underline flex items-center"
                onClick={(e) => e.stopPropagation()}
              >
                {category.top_image_url && category.top_image_url != '' && (
                  <img
                    src={category.top_image_url}
                    alt={category.name}
                    className="category-card-image h-[35px] object-cover mr-2"
                  />
                )}
                {category.name}
              </a>
            </AccordionTrigger>
            <AccordionContent className="px-6 py-4 text-sm whitespace-pre-line">
              {category.subcategories
                .filter((subcategory) => !subcategory.hide)
                .sort((a, b) => a.sort_order - b.sort_order)
                .map((subcategory, index) => {
                  return (
                    <Link
                      href={`/${service.is_replaced ? 'search' : 'subcategory'}/${subcategory.subcategory_id}`}
                      key={index}
                      className="category-card flex justify-between px-4 items-center h-[46px] border-t border-[#efefef]"
                    >
                      <div className="flex items-center">
                        {subcategory.top_image_url &&
                          subcategory.top_image_url != '' && (
                            <img
                              src={subcategory.top_image_url}
                              alt={subcategory.name}
                              className="subcategory-card-image h-[35px] object-cover mr-2"
                            />
                          )}
                        <span className="ml-2">{subcategory.name}</span>
                      </div>
                      <div className="arrow-right-circle-icon flex items-center">
                        <ArrowRightCircleIcon />
                      </div>
                    </Link>
                  )
                })}
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      )}
      {category && category.subcategories.length == 0 && (
        <Link
          href={`/category/${category.category_id}`}
          className="category-card flex justify-between px-4 items-center h-[46px] border-t border-[#efefef]"
        >
          <div className="flex items-center">
            {category.top_image_url && category.top_image_url != '' && (
              <img
                src={category.top_image_url}
                alt={category.name}
                className="category-card-image h-[35px] object-cover"
              />
            )}
            <span className="ml-4 font-semibold">{category.name}</span>
          </div>
          <div className="arrow-right-circle-icon flex items-center">
            <ArrowRightCircleIcon />
          </div>
        </Link>
      )}
    </>
  )
}
