import { serviceType } from '@/lib/service'
import { subcategoryType } from '@/lib/subcategory'
import { Dispatch, SetStateAction } from 'react'
import { CategoryCard } from './parts/categoryCard'

type SubCategoryListProps = {
  subcategories: subcategoryType[]
  open?: boolean
  setOpen?: Dispatch<SetStateAction<boolean>>
  service: serviceType
}

export const SubCategoryList: React.FC<SubCategoryListProps> = ({
  subcategories,
  open,
  setOpen,
  service,
}) => {
  return (
    <div
      className=""
      style={{ width: '94%', margin: '12px auto', overflow: 'hidden' }}
    >
      <div className="mb-4">
        <h2 className="text-base font-bold">人気カテゴリ一覧</h2>
      </div>
      <div className="text-center">
        {subcategories.map((v) => (
          <div
            key={v.subcategory_id}
            onClick={() => {
              if (open && setOpen) {
                setOpen(false)
              }
            }}
          >
            <CategoryCard subcategory={v} service={service} />
          </div>
        ))}
      </div>
    </div>
  )
}
